<template>
    <TopBar :id="id" @itemClicked="handleItemClick" />
    <div class="container">
        <Blog v-if="id==1" />
        <Prodotti v-if="id==2" />
        <Contacts v-if="id==3" />
    </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue';
import Blog from '@/views/Blog.vue';
import Prodotti from '@/views/Prodotti.vue';
import Contacts from '@/views/Contacts.vue';
import '@fortawesome/fontawesome-free/css/all.css';

export default {
  components: {
    TopBar,
    Blog,
    Prodotti,
    Contacts,
  },
  data() {
    return {
      id: 1
    }
  },
  methods: {
    handleItemClick(id) {
      console.log("ID ricevuto dal figlio:", id);
      this.id = id
    }
  }
}
</script>

<style scoped>
.container {
    margin: 25px;
    padding: 25px;
    background: white;
    border-radius: 10px;
}
</style>