<template>
  <div>
    <h1> Articoli del blog </h1>
    <button @click="openModal">Aggiungi nuovo articolo</button>
    <FullScreenModal :isVisible="isModalVisible" @close="closeModal">
      <div class="blog-dialog">
      <h2>{{temp_article?.blog_id ? 'Modifica' : 'Aggiungi'}} nuovo articolo</h2>
      <p>
        <select v-model="temp_article.language">
          <option value="it">Italiano</option>
          <option value="en">English</option>
          <!-- Aggiungi altre lingue se necessario -->
        </select>
      </p>
      <p>
        <img @click="triggerFileInput" v-if="temp_article?.image" :src="temp_article?.image">
        <br>
        <input ref="fileInput" type="file" @change="uploadToFirebaseMainImage">
      </p>
      <p><label>Titolo articolo:</label><input type="text" v-model="temp_article.title" placeholder="Titolo articolo"></p>
      <p><label>Titolo SEO:</label><input type="text" v-model="temp_article.meta_title" placeholder="Titolo pagina SEO"></p>
      <p><label>Descrizione SEO:</label><input type="text" v-model="temp_article.meta_description" placeholder="Descrizione SEO"></p>
      <p>
        <tinymce-editor :api-key="editorConfig.apiKey" v-model="temp_article.content" :init="editorConfig"></tinymce-editor>
      </p>
      <p> <button @click="saveAddModifyArticle">Salva l'articolo</button> <small> oppure </small> <button @click="closeModal">Chiudi e annulla</button></p>

      </div>
    </FullScreenModal>
    <!-- Filtri -->
    <div class="filters">
      <label>Lingua:</label>
      <select v-model="language" @change="fetchBlogs">
        <option value="">Tutte</option>
        <option value="en">English</option>
        <option value="it">Italiano</option>
        <!-- Aggiungi altre lingue se necessario -->
      </select>

      <label>Cerca:</label>
      <input v-model="search" @input="fetchBlogs" placeholder="Cerca per titolo..." />
    </div>

    <!-- Tabella -->
    <table>
      <thead>
        <tr>
          <th>Blog ID</th>
          <th>Lingua</th>
          <th>Titolo</th>
          <th>Slug</th>
          <th>Data</th>
          <th>Azioni</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="blog in blogs" :key="blog.blog_id">
          <td>{{ blog.blog_id }}</td>
          <td>{{ blog.language }}</td>
          <td>{{ blog.title }}</td>
          <td>{{ blog.slug }}</td>
          <td>{{ blog.date_posted	}}</td>
          <td>
            <button @click="loadModify(blog)">Modifica</button><br>
            <button @click="deleteProduct(blog)">Cancella</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination">
        <button @click="currentPage--" :disabled="currentPage === 1">Precedente</button>
        <span>Pagina {{ currentPage }} di {{ totalPages }}</span>
        <button @click="currentPage++" :disabled="currentPage === totalPages || totalPages == 0">Successivo</button>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import FullScreenModal from '@/components/FullScreenModal.vue';
import Editor  from '@tinymce/tinymce-vue';
import { storage, firebase_reference, uploadBytes, getDownloadURL} from '@/plugins/firebaseConfig.js';

export default {
  components: {
    FullScreenModal, 'tinymce-editor': Editor
  },
  data() {
    return {
      temp_article: {
        blog_id: null,
        language: 'it',
        title: '',
        slug: '',
        content: '',
        meta_title: '',
        meta_description: '',
        image: require('@/assets/468x200.png')
      },
      isModalVisible: false,
      blogs: [],
      language: '',
      search: '',
      currentPage: 1,
      totalPages: 0,
      editorConfig: {

      },
    };
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('token')
    this.fetchBlogs();
  },
  created () {
    this.editorConfig = {
      apiKey: 'se020mh3b95h8ziwhkobn8ixtviqqpm0m82q95ka5zy3ankn',
      // Qui puoi inserire le tue configurazioni per TinyMCE.
      // Per esempio:
      height: 300,
      menubar: false,
      language: 'it',
      images_upload_handler: this.uploadToFirebase,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount image'
      ],
      toolbar: 'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | image | code | help '
    }
  },
  watch: {
    currentPage: {
      handler: 'fetchBlogs', // Richiama fetchBlogs quando currentPage cambia
      immediate: false, // Non vogliamo che venga eseguito immediatamente, ma solo quando effettivamente cambia
    },
  },
  methods: {
     async deleteArticle(id) {
        if (!window.confirm("Sei sicuro di voler eliminare questo articolo?")) {
            return;
        }

        try {
            await axios.delete('/private/blogs/' + id);
            this.closeModal();
            window.alert('Articolo #' + id + ' rimosso con successo')
            this.fetchBlogs()
        } catch (error) {
            console.error("An error occurred:", error);
        }
    },
    loadModify (a) {
      this.temp_article = a
      this.openModal()
    },
    async saveAddModifyArticle () {
      try {
        if (this.temp_article.blog_id) {
          await axios.post('/private/blogs', this.temp_article);
        } else {
          await axios.put('/private/blogs/' + this.temp_article.blog_id, this.temp_article);
        }
        this.closeModal();
        window.alert('Articolo aggiunto con successo')
        this.fetchBlogs()
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    async uploadToFirebase(blobInfo, success, failure) {
      let blob = blobInfo.blob();
      try {
          const imageName = Date.now() + '_' + blobInfo.filename();
          const imageRef = firebase_reference(storage, 'blog/' + imageName); // Assicurati di definire il percorso corretto
          await uploadBytes(imageRef, blob);
          const downloadURL = await getDownloadURL(imageRef);
          success(downloadURL);
      } catch (error) {
          failure('Errore nel caricamento dell\'immagine: ' + error.message);
      }
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.temp_article = {
        blog_id: null,
        language: 'it',
        title: '',
        slug: '',
        content: '',
        meta_title: '',
        meta_description: '',
        image: require('@/assets/468x200.png')
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async uploadToFirebaseMainImage(event) {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const imageName = Date.now() + '_' + file.name;
            const imageRef = firebase_reference(storage, 'blog/' + imageName); // Assicurati di definire il percorso corretto
            await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(imageRef);
            this.temp_article.image = downloadURL;
        } catch (error) {
            console.error('Errore durante il caricamento dell\'immagine principale: ', error.message);
        }
    },
    async fetchBlogs() {
      try {
        const response = await axios.get('/blogs', {
          params: {
            language: this.language,
            search: this.search,
            page: this.currentPage // Aggiunto
          }
        });
        this.blogs = response.data.blogs;
        this.totalPages = response.data.totalPages; // Assumendo che l'API restituisca un campo totalPages
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
  },
};
</script>


<style scoped>
/* Generale */
div {
  font-family: 'Arial', sans-serif;
}

/* Filtri */
.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
}

label {
  margin-right: 10px;
  font-weight: 600;
  margin-left: 25px;
}

select, input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.2s;
    background: #efefef;
}

select:focus, input:focus {
  outline: none;
  background-color: #eef2f7;
}

/* Tabella */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

thead {
  background-color: #007BFF;
  color: white;
}

th, td {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}

tr:last-child td {
  border-bottom: none;
}

/* Controlli di paginazione */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

button {
  padding: 10px 15px;
  margin: 0 10px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  transition: background-color 0.2s;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #c4c4c4;
  cursor: not-allowed;
}
h1{
    font-family: Arial;
    font-weight: 500;
    margin-top: 0px;
    color: #385969;
}
.blog-dialog p, .blog-dialog h2{
  text-align: center;
}
.blog-dialog img {
  border-radius: 10px;
}
.blog-dialog input {
    min-width: 450px;
    margin-top: 10px;
}
.blog-dialog label {
    font-size: 12px;
    width: 100px;
    display: inline-block;
}
</style>