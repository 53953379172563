<template>
  <div class="login-container">
    <img :src="logo" class="logo">
    <h2 class="login-title"><b>Bentornato</b> su Next Extrusion <br><small>admin panel</small></h2>
    <input class="login-input" v-model="username" placeholder="Username" />
    <input class="login-input" type="password" v-model="password" placeholder="Password" />
    <button class="login-button btn-grad" @click="login">Accedi all'area riservata</button>
    <p class="error-message" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';  // Assicurati di sostituire con il percorso corretto al tuo file router.js

export default {
  data() {
    return {
      logo: require('@/assets/logo.png'),
      username: '',
      password: '',
      error: null
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('/login', {
          username: this.username,
          password: this.password
        });

        if (response.data.auth) {
          this.$emit('login-success', response.data.token);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
          localStorage.setItem('token', response.data.token);

          // Reindirizza l'utente a /
          router.push('/');

        } else {
          this.error = 'Hai sbagliato username o password, riprova.';
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.data) {
          this.error = error.response.data;
        } else {
          this.error = 'Si è verificato un errore imprevisto.';
        }
      }
    }
  }
};
</script>


<style scoped>
/* Contenitore principale */
.login-container {
  margin-top: 100px!important;
  width: 100%;
  max-width: 400px;
  padding: 40px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

/* Titolo */
.login-title {
  font-size: 24px;
  margin-bottom: 25px;
  font-weight: 600;
  font-family: Arial;
  text-align: center;
}

/* Input fields */
.login-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

h2.login-title {
    color: #37596a;
}

/* Pulsante di login */
.login-button {
  width: 100%;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}


.btn-grad {
  background-image: linear-gradient(to right, #16222A 0%, #3A6073  51%, #16222A  100%);
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

/* Messaggi di errore */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.logo{
  max-width: 100px;
  display: inline-block;
  text-align: center;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}
</style>