<template>
  <div class="top-bar">
    <img class="menu-item logo" :src="logo">
    <div class="menu-item" v-for="item in menuItems" :key="item.id" @click="sendIdToParent(item.id)">
      <i :class="item.icon"></i>
      <span>{{ item.label }}</span>
    </div>
    <div @click="logoutF" class="logout menu-item">
        <i class="fas fa-power-off"></i>
      <span>Logout</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopBar',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      logo: require('@/assets/logo.png'),
      menuItems: [
        { id: 1, icon: "fas fa-blog", label: "Articoli del blog" },
        { id: 2, icon: "fas fa-box", label: "Prodotti e categorie" },
        { id: 3, icon: "fas fa-envelope", label: "Messaggi e richieste" },
        // Aggiungi altre voci di menu qui come necessario
      ]
    }
  },
  methods: {
    sendIdToParent(id) {
      this.$emit('itemClicked', id);
    },
    logoutF() {
      localStorage.removeItem('token');
      window.location.reload()
    }
  }
}
</script>

<style scoped>
.top-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #efefef;
    border-radius: 10px;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.2);
    margin: 25px;
}

.menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  transition: all .5s;
}
.menu-item:hover *{
    color:#6f6f6f!important;
    transition: all .2s;
}
.menu-item i {
  font-size: 24px;
  color: #37596a;
}

.menu-item span {
  font-size: 12px;
  color: #37596a;
  margin-top: 5px;
}
*{
    font-family: Arial;
}
@media (max-width: 600px) {
    .menu-item span {
        display: none;
    }
}
@media (min-width: 600px) {
    .logout{
        position: absolute;
        right: 35px;
    }
    .logout *{
        color: #6e6e70!important;
    }
    img.menu-item.logo {
        position: absolute;
        left: 35px;
    }
}
img.menu-item.logo {
    max-height: 40px;
}
</style>
