<template>
  <div>
    <h1> Richieste contatti </h1>
    <!-- Filtri -->
    <div class="filters">
      <label>Status:</label>
      <select v-model="language" @change="fetchRequests">
        <option value="">Tutte</option>
        <option value="1">Già viste</option>
        <option value="0">Non viste</option>
        <!-- Aggiungi altre lingue se necessario -->
      </select>

      <label>Cerca:</label>
      <input v-model="search" @input="fetchRequests" placeholder="Cerca per titolo..." />
    </div>

    <div class="message" v-if="temp">
      <b>Codice: </b> #{{temp.request_id}}<br><br>
      {{temp.name}} | <a :href="'mailto:' + temp.email">{{temp.email}}</a><br><br>
      <i>{{temp.message}}</i><br><br>
    </div>

    <!-- Tabella -->
    <table>
      <thead>
        <tr>
          <th>Req ID</th>
          <th>email</th>
          <th>Letta</th>
          <th>datetime</th>
          <th>azioni</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="(req, i) in requests" :key="req.request_id ">
          <td>#{{ req.request_id  }}</td>
          <td>{{ req.email }}</td>
          <td>{{ (req.viewed == 1) ? '✔️' : '❌' }}</td>
          <td>{{ req.datetime }}</td>
          <td>
            <button style="margin-bottom:5px" @click="open(req)">Apri e leggi messaggio</button><br>
            <button @click="setViewed(req.request_id, i)" :disabled="req.viewed == 1">Contrassegna come letta</button><br>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination">
        <button @click="currentPage--" :disabled="currentPage === 1">Precedente</button>
        <span>Pagina {{ currentPage }} di {{ totalPages }}</span>
        <button @click="currentPage++" :disabled="currentPage === totalPages || totalPages == 0">Successivo</button>
    </div>



  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      requests: [],
      temp: null,
      language: '',
      search: '',
      currentPage: 1,
      totalPages: 0 // Aggiunto
    };
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('token')
    this.fetchRequests();
  },
  watch: {
    currentPage: {
      handler: 'fetchRequests', // Richiama fetchRequests quando currentPage cambia
      immediate: false, // Non vogliamo che venga eseguito immediatamente, ma solo quando effettivamente cambia
    },
  },
  methods: {
    async setViewed (id, i) {
      try {
        await axios.put('/private/contact-requests/'+ id +'/viewed');
        window.alert('Messaggio ID #'+ id + ' contrassegnato come LETTO')
        this.requests[i].viewed = true
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    open (req) {
      this.temp = req
    },
    close () {
      this.temp = null
    },
    async fetchRequests() {
      try {
        const response = await axios.get('/private/contact-requests/', {
          params: {
            search: this.search,
            page: this.currentPage // Aggiunto
          }
        });
        this.requests = response.data.requests;
        this.totalPages = response.data.totalPages || 0; // Assumendo che l'API restituisca un campo totalPages
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
  },
};
</script>


<style scoped>
/* Generale */
div {
  font-family: 'Arial', sans-serif;
}

/* Filtri */
.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
}

label {
  margin-right: 10px;
  font-weight: 600;
  margin-left: 25px;
}

select, input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.2s;
    background: #efefef;
}

select:focus, input:focus {
  outline: none;
  background-color: #eef2f7;
}

/* Tabella */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

thead {
  background-color: #007BFF;
  color: white;
}

th, td {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}

tr:last-child td {
  border-bottom: none;
}

/* Controlli di paginazione */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

button {
  padding: 10px 15px;
  margin: 0 10px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  transition: background-color 0.2s;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #c4c4c4;
  cursor: not-allowed;
}
h1{
    font-family: Arial;
    font-weight: 500;
    margin-top: 0px;
    color: #385969;
}
.message{
  background: #fafafa;
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
}
</style>