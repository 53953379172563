import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAt1d2S3XXZ3PqQIYWpwfbPzIpB04vpm3Q",
    authDomain: "next-website-2ee53.firebaseapp.com",
    projectId: "next-website-2ee53",
    storageBucket: "next-website-2ee53.appspot.com",
    messagingSenderId: "215677766652",
    appId: "1:215677766652:web:83501103a0722c113a8079"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const storage = getStorage(app);
  const firebase_reference = ref;
  export { app, auth, db, storage, firebase_reference, uploadBytes, getDownloadURL };

