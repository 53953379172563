<template>
  <div>
    <h1> Prodotti e categorie </h1>
    <button @click="openModal(false)">Aggiungi nuovo prodotto</button>
    <button @click="openModal(true)">Aggiungi una categoria</button>
    <FullScreenModal :isVisible="isModalVisible" @close="closeModal">
      <div class="product-dialog">
      <h2>{{temp_product?.blog_id ? 'Modifica' : 'Aggiungi'}} nuovo prodotto</h2>
      <p>
        <select v-model="temp_product.language">
          <option value="it">Italiano</option>
          <option value="en">English</option>
          <!-- Aggiungi altre lingue se necessario -->
        </select>
      </p>
      <p>
        <label>Categoria: </label>
        <select  v-model="temp_product.category_id">
          <option v-for="category in categories" :key="'cat'+category.category_id" :value="category.category_id">{{category.name}}</option>
          <!-- Aggiungi altre lingue se necessario -->
        </select>
      </p>
      <p>
        <img @click="triggerFileInput" v-if="temp_product?.image" :src="temp_product?.image">
        <br>
        <input ref="fileInput" type="file" @change="uploadToFirebaseMainImage">
      </p>
      <p><label>Nome prodotto:</label><input v-model="temp_product.name" type="text" placeholder="Nome prodotto"></p>
      <p><label>Titolo SEO:</label><input type="text" v-model="temp_product.meta_title" placeholder="Titolo pagina SEO"></p>
      <p><label>Descrizione SEO:</label><input type="text" v-model="temp_product.meta_description" placeholder="Descrizione SEO"></p>
      <p>
        <tinymce-editor :api-key="editorConfig.apiKey" v-model="temp_product.description" :init="editorConfig"></tinymce-editor>
      </p>
      <p> <button @click="saveAddModifyProduct">Salva il prodotto</button> <small> oppure </small> <button @click="closeModal">Chiudi e annulla</button></p>

      </div>
    </FullScreenModal>
    <FullScreenModal :isVisible="isModalVisibleCategory" @close="closeModal">
      <div class="product-dialog">
      <h2>Aggiungi categoria</h2>
      <p>
        <select v-model="temp_category.language">
          <option value="it">Italiano</option>
          <option value="en">English</option>
          <!-- Aggiungi altre lingue se necessario -->
        </select>
      </p>

      <p>
        <img @click="triggerFileInput" v-if="temp_category?.image" :src="temp_category?.image">
        <br>
        <input ref="fileInput" type="file" @change="uploadToFirebaseMainImage">
      </p>
      <p><label>Nome categoria:</label><input type="text" v-modal="temp_category.name" placeholder="Nome categoria"></p>
      <p> <button @click="saveAddModifyCategory">Salva categoria</button> <small> oppure </small> <button @click="closeModal">Chiudi e annulla</button></p>

      </div>
    </FullScreenModal>
    <!-- Filtri -->
    <div class="filters">
      <label>Lingua:</label>
      <select v-model="language" @change="fetchProducts">
        <option value="">Tutte</option>
        <option value="en">English</option>
        <option value="it">Italiano</option>
        <!-- Aggiungi altre lingue se necessario -->
      </select>

      <label>Cerca:</label>
      <input v-model="search" @input="fetchProducts" placeholder="Cerca per titolo..." />
    </div>
    <CategoryBlock :categories="categories" @category-selected="handleCategorySelected" @delete-category="handleDeleteCategory" />

    <!-- Tabella -->
    <table>
      <thead>
        <tr>
          <th>Product ID</th>
          <th>Categoria</th>
          <th>Lingua</th>
          <th>Nome</th>
          <th>Slug</th>
          <th>Azioni</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="p in products" :key="p.product_id">
          <td>{{ p.product_id }}</td>
          <td>{{ getCategory(p.category_id) }}</td>
          <td>{{ p.language }}</td>
          <td>{{ p.name }}</td>
          <td>{{ p.slug }}</td>
          <td>
            <button @click="loadModify(p)">Modifica</button><br>
            <button @click="deleteProduct(p)">Cancella</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination">
        <button @click="currentPage--" :disabled="currentPage === 1">Precedente</button>
        <span>Pagina {{ currentPage }} di {{ totalPages }}</span>
        <button @click="currentPage++" :disabled="currentPage === totalPages || totalPages == 0">Successivo</button>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import CategoryBlock from '@/components/CategoryBlock.vue';
import FullScreenModal from '@/components/FullScreenModal.vue';
import Editor  from '@tinymce/tinymce-vue';
import { storage, firebase_reference, uploadBytes, getDownloadURL} from '@/plugins/firebaseConfig.js';

export default {
  components: {
    CategoryBlock, FullScreenModal, 'tinymce-editor': Editor
  },
  data() {
    return {
      products: [],
      language: '',
      temp_product: {
        product_id: null,
        category_id: null,
        language: 'it',
        name: '',
        slug: '',
        description: '',
        meta_title: '',
        meta_description: '',
        image: require('@/assets/468x200.png')
      },
      temp_category: {
        language: 'it',
        name: '',
        image: require('@/assets/468x200.png')
      },
      search: '',
      currentPage: 1,
      totalPages: 0, // Aggiunto
      categories: [
        /*{
          category_id: 1,
          language: 'it',
          name: 'Categoria di prova',
          slug: 'categoria-di-prova',
          image: 'https://www.nextextrusion.it/images/catalogo/guide%20e%20cass.jpg'
        }*/
      ],
      isModalVisible: false,
      isModalVisibleCategory: false,
      editorConfig: {},

    };
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('token')
    this.fetchProducts();
    this.fetchCategories();
  },
  created () {
    this.editorConfig = {
      apiKey: 'se020mh3b95h8ziwhkobn8ixtviqqpm0m82q95ka5zy3ankn',
      // Qui puoi inserire le tue configurazioni per TinyMCE.
      // Per esempio:
      height: 300,
      menubar: false,
      language: 'it',
      images_upload_handler: this.uploadToFirebase,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount image'
      ],
      toolbar: 'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | image | code | help '
    }
  },
  watch: {
    currentPage: {
      handler: 'fetchProducts', // Richiama fetchBlogs quando currentPage cambia
      immediate: false, // Non vogliamo che venga eseguito immediatamente, ma solo quando effettivamente cambia
    },
  },
  methods: {
    getCategory (id) {
      let x = this.categories.filter(it => { return it.category_id == id})
      if (x.length > 0) {
        return x[0].name
      } else {
        return 'not found'
      }
    },
    async handleDeleteCategory(id) {
        if (!window.confirm("Sei sicuro di voler eliminare questa categoria?")) {
            return;
        }

        try {
            await axios.delete('/private/category/' + id);
            this.closeModal();
            window.alert('Categoria rimossa con successo')
            this.fetchCategories()
        } catch (error) {
            console.error("An error occurred:", error);
        }
    },
    async deleteProduct(id) {
        if (!window.confirm("Sei sicuro di voler eliminare questo prodotto?")) {
            return;
        }

        try {
            await axios.delete('/private/product/' + id);
            this.closeModal();
            window.alert('Prodotto rimosso con successo')
            this.fetchProducts()
        } catch (error) {
            console.error("An error occurred:", error);
        }
    },
    async saveAddModifyProduct () {
      try {
        if (this.temp_product.product_id) {
          await axios.post('/private/product', this.temp_product);
        } else {
          await axios.put('/private/product/' + this.temp_product.product_id, this.temp_product);
        }
        this.closeModal();
        window.alert('Prodotto aggiunto con successo')
        this.fetchProducts()
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    async saveAddModifyCategory () {
      try {
        await axios.post('/private/category', this.temp_category);
        this.closeModal();
        window.alert('Categoria aggiunta con successo')
        this.fetchCategories()
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    loadModify (p) {
      this.temp_product = p
      this.openModal(false)
    },
    async uploadToFirebase(blobInfo, success, failure) {
      let blob = blobInfo.blob();
      try {
          const imageName = Date.now() + '_' + blobInfo.filename();
          const imageRef = firebase_reference(storage, 'blog/' + imageName); // Assicurati di definire il percorso corretto
          await uploadBytes(imageRef, blob);
          const downloadURL = await getDownloadURL(imageRef);
          success(downloadURL);
      } catch (error) {
          failure('Errore nel caricamento dell\'immagine: ' + error.message);
      }
    },
    openModal(cat) {
      if (!cat) {
        this.isModalVisible = true;
      } else {
        this.isModalVisibleCategory = true;
      }
    },
    closeModal() {
      this.isModalVisibleCategory = false
      this.isModalVisible = false;
      this.temp_product = {
        product_id: null,
        category_id: null,
        language: 'it',
        name: '',
        slug: '',
        description: '',
        meta_title: '',
        meta_description: '',
        image: require('@/assets/468x200.png')
      }
      this.temp_category = {
        language: 'it',
        name: '',
        image: require('@/assets/468x200.png')
      }
      this.fetchCategories()
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async uploadToFirebaseMainImage(event) {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const imageName = Date.now() + '_' + file.name;
            const imageRef = firebase_reference(storage, 'blog/' + imageName); // Assicurati di definire il percorso corretto
            await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(imageRef);
            this.temp_product.image = downloadURL;
            this.temp_category.image = downloadURL;
        } catch (error) {
            console.error('Errore durante il caricamento dell\'immagine principale: ', error.message);
        }
    },
    async handleCategorySelected(categoryId) {
      console.log("Categoria selezionata:", categoryId);
      try {
        const response = await axios.get('/products', {
          params: {
            language: this.language,
            search: this.search,
            page: this.currentPage, // Aggiunto
            category_id: categoryId
          }
        });
        this.products = response.data.products;
        this.totalPages = response.data.totalPages; // Assumendo che l'API restituisca un campo totalPages
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get('/private/categories', {
          params: {
            language: this.language,
            search: this.search,
            page: this.currentPage // Aggiunto
          }
        });
        this.categories = []
        this.categories = response.data;
        //console.log(this.categories)
        //this.totalPages = response.data.totalPages; // Assumendo che l'API restituisca un campo totalPages
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get('/products', {
          params: {
            language: this.language,
            search: this.search,
            page: this.currentPage // Aggiunto
          }
        });
        this.products = response.data;
        this.totalPages = response.data.totalPages; // Assumendo che l'API restituisca un campo totalPages
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
  },
};
</script>


<style scoped>
/* Generale */
div {
  font-family: 'Arial', sans-serif;
}

/* Filtri */
.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
}

label {
  margin-right: 10px;
  font-weight: 600;
  margin-left: 25px;
}

select, input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.2s;
    background: #efefef;
}

select:focus, input:focus {
  outline: none;
  background-color: #eef2f7;
}

/* Tabella */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

thead {
  background-color: #007BFF;
  color: white;
}

th, td {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}

tr:last-child td {
  border-bottom: none;
}

/* Controlli di paginazione */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

button {
  padding: 10px 15px;
  margin: 0 10px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  transition: background-color 0.2s;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #c4c4c4;
  cursor: not-allowed;
}
h1{
    font-family: Arial;
    font-weight: 500;
    margin-top: 0px;
    color: #385969;
}
.product-dialog p, .product-dialog h2{
  text-align: center;
}
.product-dialog img {
  border-radius: 10px;
}
.product-dialog input {
    min-width: 450px;
    margin-top: 10px;
}
.product-dialog label {
    font-size: 12px;
    width: 100px;
    display: inline-block;
}
.category-block {
    width: 175px!important;
}
</style>