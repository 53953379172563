<template>
  <div class="category-container">
    <div
      v-for="category in categories"
      :key="category.category_id"
      class="category-block"
      :class="{ 'selected': selectedCategory === category.category_id }"
      @click="selectCategory(category.category_id)"
    >
      <img :src="category.image" alt="Categoria Immagine" class="category-image" />
      <p class="category-name">{{ category.name }}</p>
      <i class="fas fa-times delete-icon" @click.stop="deleteCategory(category.category_id)"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedCategory: null
    };
  },
  methods: {
    selectCategory(categoryId) {
      this.selectedCategory = categoryId;
      this.$emit('category-selected', categoryId);
    },
    deleteCategory(categoryId) {
      this.$emit('delete-category', categoryId);
    }
  }
};
</script>

<style scoped>
.category-container {
  display: flex;
  flex-wrap: wrap;
}
.category-block {
    margin: 10px;
    padding: 15px;
    position: relative;
    cursor: pointer;
    border-radius: 15px;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.2);
}
.category-block.selected {
  background-color: #385969;
  color: white;
}
.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
img.category-image {
    width: 60px;
    text-align: center;
}
.category-container {
    text-align: center;
}
.category-container {
    margin-top: 25px;
    margin-bottom: 25px;
    max-height: 170px;
    overflow: auto;
}
</style>
