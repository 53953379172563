<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
#app{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  background: #fafafa;
  height: 100vh;
}
body{
  width:100%;
  margin: 0px;
  padding: 0px;
}
</style>