import axios from 'axios';

export async function isTokenValid(token) {
  try {
    const response = await axios.get('/secure', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.status === 200;
  } catch (error) {
    return false;
  }
}

export async function getToken() {
  return window.localStorage.getItem('token')
}