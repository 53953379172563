<!-- FullScreenModal.vue -->
<template>
  <div v-if="isVisible" class="modal-backdrop">
    <div class="modal">
      <slot></slot>
      <button class="action" @click="close"><i class="fas fa-close"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.modal-backdrop {
    z-index: 99999!important;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background-color: white;
  width: 90%;
  min-height: 500px;
  padding: 20px;
  border-radius: 5px;
  max-height: 90%;
  overflow: auto;
}
button.action {
    position: absolute;
    right: 0px;
    top: 0px;
    background: transparent;
    border: none;
    font-size: 25px;
    font-weight: 300;
    color: #b7b7b7;
    cursor: pointer;
}
</style>
