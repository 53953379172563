import { createRouter, createWebHistory } from 'vue-router';
import Login from './components/Login.vue';
import Home from './views/Home.vue'; // supponendo che tu abbia un componente Home per la pagina principale
import { isTokenValid } from './utils';

const routes = [
    {
      path: '/',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      component: Login
    }
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  const isAuthenticated = token && await isTokenValid(token);

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    localStorage.removeItem('token');
    next('/login');
  } else {
    next();
  }
});


export default router;
